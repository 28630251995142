<template>
  <div class="ArticleIntroBlock" :class="{open: classOpen}">
    <div class="Article">
      <!-- open/close state of article = method toggleBlock => toggle classOpen -->
      <div class="clickArea" @click="toggleBlock"></div>
      <div class="catList">{{ converCatList }}</div>
      <div class="image" v-if="article.image!=''"><img :src="domainUrl+article.image"></div>
      <h3 class="blockTitle">{{ article.title }}</h3>
      <div class="link"><a class="readmore" aria-label="Lees meer" :href="domainUrl+article.url"></a></div>
      <div class="intro" v-html="decodeArticle"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ArticleIntroBlock',
  props: {
    article : Object
  },
  data() {
    return {
      classOpen : false
    }
  },
  computed: {
      decodeArticle() {
          const intro = decodeURIComponent(this.article.intro);
        return intro
      },
      converCatList(){
          return this.article.catList.replace( /(,)(.*?\+)/gm, ', ' );
      },
      domainUrl() {
          return window.waxtrapp.domainURL;
      }
  }, 
  methods: {
    toggleBlock() {
      this.classOpen = !this.classOpen;
    }
  },
     
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
