<template>
  <div class="CategorySelection">
    <div class="oneSelection">
      <ul class="categories">
        <!-- All categorys are listed in li(category) /ul /li (subcat) -->
        <li v-for="category in categories" :key="category.id">
          <h3 class="category">{{ category.name }}</h3>
          <ul class="selections">
            <li v-for="subcategory in category.children" :key="subcategory.id">
              <!-- input checkbox, has waiting (debounce) so not every click give a new request to server -->
              <!-- on change of checkbox method getArticleIds is used, there rest of filterPage gives the articles to show -->
              <!-- emit articleIdsUpdate with requestedArticleIds -->
              <!-- selectedCategories is array with selected items (auto fill vue) -->
              <input v-debounce:500ms="getArticleIds" type="checkbox" :id="subcategory.id" :value="subcategory.id" v-model="selectedCategories">
              <label :for="subcategory.id">{{ subcategory.name }}</label>
            </li>  
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

import axios from '../services/axios.js';
import vueDebounce from 'vue-debounce';

import Vue from 'vue';
Vue.use(vueDebounce, { listenTo: 'onchange'/* options*/});

export default {
  name: 'CategorySelection',
  props: {
    startcat: Array
  },
  data() {
    return {
        message: "",
        loading: false,
        categories: [],
        mainCategoryIds: [],
        selectedCategories: [],
        requestedArticleIds: []
    }
  },
  computed: {
  },   
  methods: {
    getCategories: function () {
      this.loading = true;
      axios().get('/categories', {params:{selected:1}})
      .then(response => {
        this.loading = false;
        this.categories = response.data.data;
        this.fillMainCatIds();
        this.preFillSelectedItems();
        this.getArticleIds();
      })
      .catch(error => {
        this.loading = false;
        console.log(error)
      })
    },
    fillMainCatIds() {
      let mainCat = [];
      for (let i = 0; i < this.categories.length; i++) {
        mainCat.push(this.categories[i].id);
      }
      this.mainCategoryIds = mainCat;
    },
    preFillSelectedItems() {
      let arrayCat = [];
      this.startcat.forEach(selectedItem => {
        arrayCat.push(selectedItem);
      });
      this.selectedCategories = arrayCat;
    },
    fillSelectedIds() {
      let selectCat = [];
      for (let i = 0; i < this.categories.length; i++) {
        if ( this.categories[i].children != undefined ) {
          for (let j = 0; j < this.categories[i].children.length; j++) {
            selectCat.push(this.categories[i].children[j].id);
          }
        }
      }
      this.selectedCategories = selectCat;
    },
    getArticleIds() {
      this.loading = true;
      axios().get('/articles/_idsByCategories', {params:{mainCategoryIds:this.mainCategoryIds.join(','),categoryIds:this.selectedCategories.join(',')}})
      .then(response => {
        this.loading = false;
        this.requestedArticleIds = response.data.data;
        this.$emit('articleIdsUpdate', this.requestedArticleIds);    
        const event= new Event('blockViewUpdate');
        this.$nextTick(() => {
          // The whole view is rendered, so I can safely access or query
          dispatchEvent(event);
          console.log('update view, trigger event blockViewUpdate');
        }); 
      })
      .catch(error => {
        this.loading = false;
        console.log(error)
      })
    },
  },
  created () {
  },
  mounted(){
    this.getCategories();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
