import axios from 'axios'

if (process.env.NODE_ENV === "development") { require('../config/config.js'); }

var headers = {
    'Content-Type': 'text/json;charset=UTF-8',
    'Accept': 'text/json;charset=UTF-8'
};

if (process.env.VUE_APP_AUTHORIZATION) {
    headers.Authorization = process.env.VUE_APP_AUTHORIZATION;
}

var baseURL = window.waxtrapp.taxonomyRestURL;

axios.defaults.baseURL = baseURL;
axios.defaults.headers = headers;
axios.defaults.data = {};

export default() => {
    return axios.create({
        withCredentials: false,
    })
}
