<template>
  <div class="ArticleBlock" :class="{open: classOpen}">
    <div class="Article">
      <!-- open/close state of article = method toggleBlock => toggle classOpen -->
      <div class="clickArea" @click="toggleBlock"></div>
      <h3>{{ article.title }}</h3>
      <div class="body" v-html="decodeArticle"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ArticleBlock',
  props: {
    article : Object
  },
  data() {
    return {
      classOpen : false
    }
  },
  computed: {
      decodeArticle() {
          const body = decodeURIComponent(this.article.body);
        return body
      }
  }, 
  methods: {
    toggleBlock() {
      this.classOpen = !this.classOpen;
    }
  }     
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
