<template>
  <div class="BlocksBox" v-if="version==2" >
    <!-- every article (from articlesToShow) has its one component -->
    <div class="oneBlock intro" v-for="article in sorted_articlesToShow" v-bind:key="article.id">
        <ArticleIntroBlock :article="article" />
    </div>
  </div>
  <div class="BlocksBox" v-else >
    <!-- every article (from articlesToShow) has its one component -->
    <div class="oneBlock" v-for="article in sorted_articlesToShow" v-bind:key="article.id">
        <ArticleBlock :article="article" />
    </div>
  </div>
</template>

<script>
import ArticleBlock from '../components/ArticleBlock.vue';
import ArticleIntroBlock from '../components/ArticleIntroBlock.vue';
import axios from '../services/axios.js';

export default {
  name: 'BlocksBox',
  props: {
    version: Number,
    requestedIds: Array
  },
  data() {
    return {
        message: "",
        loading: false,
        requestedArticleIds: [],
        articlesToShow: [],
        articlesCache: [],
    }
  },
  components: {
    ArticleBlock,
    ArticleIntroBlock
  },
  watch: {
    requestedIds: function (val) {
      //if property requestedIds changing then, fill requestedArticleIds
      this.requestedArticleIds = val.map(function(value){ return value.id });
      this.getArticles()
    },
  },
  methods: {
    getArticles() {
      this.loading = true;
      let needFromServer = [];
      //first delete previous Articles
      this.articlesToShow = [];
      this.requestedArticleIds.forEach(articleId => {
      //first look in cache
        if ( articleId in this.articlesCache ) {
          this.articlesToShow.push(this.articlesCache[articleId]);
        } else {
          needFromServer.push(articleId);
        }
      });
      console.log ( "needFromServer:",needFromServer);
      if ( needFromServer.length > 0 ) {
        axios().get('/articles/'+ needFromServer.join(',') )
        .then(response => {
          this.loading = false;
          response.data.data.forEach(article => {
            //add to cache and show articles
            this.articlesCache[article.id] = article;
            this.articlesToShow.push(article);
          });
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        })
      }
    },
  },
  computed: {
    sorted_articlesToShow() {

      function compare(a, b) {
        //this was the sorting on startdate:
          // let pattern = /(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(.*)/;
          // let dateA = (a.startDate != undefined? new Date(a.startDate.replace(pattern,'$1-$2-$3 $4:$5')): new Date());
          // let dateB = (b.startDate != undefined? new Date(b.startDate.replace(pattern,'$1-$2-$3 $4:$5')): new Date());
          //      console.log("compare",dateA.toISOString().substring(0, 10),dateB.toISOString().substring(0, 10), (dateA > dateB)? -1:((dateA < dateB)?1:0) );

          // if (dateA > dateB) return -1;
          // if (dateA < dateB) return 1;
        // now they ask for sorting on title of article: #1504530091

          let titleA = a.title.toUpperCase();
          let titleB = b.title.toUpperCase();
        // console.log("compare",titleA, (titleA > titleB)? 1:((titleA < titleB)?-1:0) );
          if (titleA > titleB) return 1;
          if (titleA < titleB) return -1;
        return 0;
      }
      let sortedArray = this.articlesToShow;
      return sortedArray.sort(compare);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
