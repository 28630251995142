<template>
  <div class='vue-taxonomy-project row' >
    <div class="app-all col-xs-12">
      <!-- Split app in 2 components left/main -->
      <div class="app-left col-xs-12 col-sm-4 col-md-3">
        <!-- on change of selection  of category get new requested articleItems into requestedIds (with emit and method) -->
        <!-- add startcategories find on startup of app.created -->
        <CategorySelection v-on:articleIdsUpdate="changeOnRequestedArticleIds" v-bind:startcat="startcat"/>
      </div>
      <div class="app-main col-xs-12 col-sm-8 col-md-9">
        <!-- all articles are in a component "BlockBox", article cache is inside this component -->
        <!-- requestedIds are comming from CategorySelection -->
        <BlocksBox v-bind:requestedIds="requestedIds" v-bind:version="version"/>
      </div>
    </div>
  </div>
</template>

<script>
import CategorySelection from './components/CategorySelection.vue';
import BlocksBox from './components/BlocksBox.vue';

export default {
  name: 'app',
  props: {
  },
  data() {
    return {
      requestedIds:[],
      hasstartcat : false,
      startcat : [],
      version: 2 //new version
    }
  },
  components: {
    CategorySelection,
    BlocksBox
  },
  methods: {
    changeOnRequestedArticleIds( requestedIds) {
      //changes comming from CategorySelection, and are bind with BlocksBox
      this.requestedIds = requestedIds;
    }
  },
  created() {
    let queryString = window.location.search.substring(1); 
    if (queryString) {
      // let startcat = new URLSearchParams(queryString).get("startcat"); not working in IE11 grrrrr... do it yourself
      let queryElements = queryString.split('&');
      queryElements.forEach(queryItem => {
        let splitQuery = queryItem.split('=');
        let qItem = splitQuery[0];
        let qValue =splitQuery[1];
        if ((qItem == "v") && (qValue == "1")) {
            this.version=1;
        }
        if ( qItem == "startcat" ) {
          this.hasstartcat = !( qValue == null );
          this.startcat = qValue.split(',');
          console.log("params", this.hasstartcat, this.startcat);
        }
      });
    }
  },
}

</script>

<style  lang="less">
@import './styles/common.less';
</style>
